// Theme colors to overwrite default bootstrap colors.
$theme-colors: (
  "primary": #93D688,
  "secondary": rgb(91, 129, 86),
  "light": #9999aa,
  "dark": #444449,
  "white": #ffffff,
  "info": #6dd5ed,
  "facebook":#3b5998,
  "twitter":#00aced,
  "reddit":#FF5700,
  "linkedin":#0072b1,
  "pinterest":#E60023
);

// Breakpoints
$break-points: (
  "small": 576px,
  "medium": 828px,
  "large": 992px,
  "extra-large": 1200px,
)