.masthead {
  display:flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 100%;
  position: relative;
  background-image: url('../../Assets/images/site_header.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0px 50px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0,0,0,0.2);
    .title {
      font-weight: 600;
      font-size: 3rem;
      color: white;
      margin-bottom: 15px;
      margin-top: 0px;
     }
     
     .subtitle {
       color: white;
       margin-bottom: 5px;
       width: 50%;
       @media screen and (max-width: map-get($break-points, 'medium')) {
        width: 100%;
       }
     }
   }
}

section.features-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 50px 0px;
  .title {
    width: 45%;
    text-align: center;
    line-height: 50px;
    font-size: 2.5rem;
    font-weight: 600;
    color: map-get($theme-colors, "light");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 60%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 90%;
    }
  }
  .subtitle {
    width: 45%;
    text-align: center;
    font-weight: 200;
    margin: 0;
    margin-bottom: 25px;
    color: map-get($theme-colors, "light");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 50%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 75%;
    }
  }
  .divider {
    width: 100px;
    height: 2px;
    margin-bottom: 25px;
    background-color: map-get($theme-colors, "light");
  }
  .features-list {
    display: flex;
    justify-content: center;
    align-items: stretch;

    @media screen and (max-width: map-get($break-points, 'medium')) {
      flex-direction: column;
      align-items: center;
    }
    .feature {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 25%;
      margin: 35px;
      @media screen and (max-width: map-get($break-points, 'medium')) {
          width: 75%;
          margin:  15px 35px;
      }
      p {
        font-size: 1.2rem;
        color: map-get($theme-colors, "dark");
      }
      small {
        text-align: center;
        font-size: 0.9rem;
        color: map-get($theme-colors, "light");
      }
    }
  }
}

.about-us {
  height: auto;
  width: 100%;
  position: relative;
  background-image: url('../../Assets/images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  .overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .title {
      width: 45%;
      text-align: center;
      line-height: 50px;
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 25px;
      color: map-get($theme-colors, "white");
      @media screen and (max-width: map-get($break-points, 'large')) {
        width: 60%;
      }
      @media screen and (max-width: map-get($break-points, 'medium')) {
        width: 90%;
      }
    }
    .subtitle {
      width: 50%;
      text-align: center;
      font-weight: 200;
      margin: 0;
      color: map-get($theme-colors, "white");
      @media screen and (max-width: map-get($break-points, 'large')) {
        width: 50%;
      }
      @media screen and (max-width: map-get($break-points, 'medium')) {
        width: 75%;
      }
    }
    .divider {
      width: 100px;
      height: 2px;
      margin: 25px 0px 25px 0px;
      background-color: map-get($theme-colors, "white");
    }
    .content {
      width: 60%;
      text-align: center;
      font-weight: 200;
      margin: 0;
      margin-bottom: 50px;
      color: map-get($theme-colors, "white");
      @media screen and (max-width: map-get($break-points, 'medium')) {
        width: 90%;
      }
    }
  }
}

.millboard,
.swish {
  .divider {
    width: 100px;
    height: 2px;
    margin: 10px 0px 25px 0px;
    background-color: map-get($theme-colors, "dark");
  }
  img {
    width: auto;
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 50%;
      margin: 25px 0px;
    }
  }
  .point {
    font-weight: 600;
    color: map-get($theme-colors, "dark");
  }
}