body {
  font-family: 'Open Sans', sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

svg {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}