// Import Utilities
@import "../utils/general";
@import "../utils/variables";

// Import Bootstrap
@import "../../bootstrap/scss/bootstrap.scss";

// Import Custom scss - Maintain alphabetical order for consistency
@import "../../../Components/Home/home";
@import "../../../Components/Navigation/navigation";
@import "../../../Components/Contact/contact";
@import "../../../Components/Decking/decking";
@import "../../../Components/Gallery/gallery";