input, select {
  height: 46px;
  border: none;
  border-radius: 5px;
  background-color: map-get($map: $theme-colors, $key: "white");
}
textarea {
  padding-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: map-get($map: $theme-colors, $key: "white");
}

.contact-container {
  p {
    width: 50%;
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 75%;
    }
  }
}