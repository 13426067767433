/* 
  * Main Navigation
*/

nav {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  left:0;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: white;
    margin: 0px;
    padding: 0px 25px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    
    .nav-right,
    .nav-left  {
      display: flex;
      list-style: none;
      margin: 0px;
      padding: 0px;
    }
  
    .nav-left {
      justify-content: flex-start;
    }

    .nav-right {
      justify-content: flex-end;
      @media screen and (max-width: map-get($break-points, 'medium')) {
        float: none;
        display: flex;
        flex-direction: column;
        text-align: left;

        .item {
          display: none;

          a:not(.icon) {
            padding: 10px 10px;
          }
        }
      }
    }
  
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        text-align: center;
        color: map-get($theme-colors, 'dark');
        padding: 25px 25px;
        text-decoration: none;
        font-size: 13px;
        font-weight: 600;
        @media screen and (max-width: map-get($break-points, 'large')) {
          padding: 25px 10px;
        }
      }
      .divider {
        height: 35px;
        width: 1px;
        margin-right: 25px;
        background-color: map-get($theme-colors, "dark");
        @media screen and (max-width: map-get($break-points, 'medium')) {
          display: none;
        }
        @media screen and (max-width: map-get($break-points, 'large')) {
          margin-right: 10px;
        }
      }
      &.icon {
        display: none;
      }
      &.icon-item a {
        padding: 25px 5px;
        transition: ease all .2s;
        -webkit-transition: ease all .2s;
        -moz-transition: ease all .2s;
        -o-transition: ease all .2s;
      }
      &.logo a{
        color: map-get($theme-colors, "dark");
        padding: 15px 0px;
        margin: 0;
        font-size: 18px;
        font-weight: 400;
      }
      &:hover > a {
        color: map-get($theme-colors, "primary");;
      }
    }

    @media screen and (max-width: map-get($break-points, 'medium')) {
      float: none;
      display: flex;
      flex-direction: column;
      text-align: left;

      &.show .nav-right .item {
        display: flex;
      }

      .nav-right .item.icon {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
      }
    }
  }
}

/*
  * Hamburger Icon
  */

.bar-container {
  width: 25px;
  position: relative;
  .bar {
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background-color: map-get($theme-colors, "primary");
    transition: all linear 0.3s;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    &.top {
      position: absolute;
      top: 0px;
      left: 0px;
    }
    &.middle {
      position: absolute;
      top: 8px;
      left: 3px;
      width: 19px;
    }
    &.bottom {
      position: absolute;
      top: 16px;
      left: 0px;
    }
  }
  &.active {
    .bar.top {
      transform: rotate(45deg);
      top: 6px;
    }
    .bar.bottom {
      transform: rotate(-45deg);
      top: 6px;
    }
    .bar.middle {
      opacity: 0;
      top: 6px;
    }
  }
}

.footer {
  width: 100%;
  padding: 25px;
  background-color: #2f2f2f;
}