.gallery-container {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
  .title {
    width: 45%;
    text-align: center;
    line-height: 50px;
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 50px;
    color: map-get($theme-colors, "light");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 60%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 90%;
    }
  }
  .subtitle {
    width: 45%;
    text-align: center;
    font-weight: 200;
    margin: 0;
    margin-bottom: 25px;
    color: map-get($theme-colors, "light");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 50%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 75%;
    }
  }
  .divider {
    width: 100px;
    height: 2px;
    margin-bottom: 50px;
    background-color: map-get($theme-colors, "light");
  }
  .gallery {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
    img {
      width: 500px;
      height: 500px;
      margin: 25px;
      object-fit: cover;
      border-radius: 10px;
      box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25); 
      @media screen and (max-width: map-get($break-points, 'medium')) {
        width: 300px;
        height: 300px;
        margin: 25px 10px;
      }
    }
  }
}
