.about-decking {
  padding: 50px 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .title {
    width: 45%;
    text-align: center;
    line-height: 50px;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 25px;
    color: map-get($theme-colors, "dark");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 60%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 90%;
    }
  }
  .subtitle {
    width: 50%;
    text-align: center;
    font-weight: 200;
    margin: 0;
    color: map-get($theme-colors, "dark");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 50%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 75%;
    }
  }
  .divider {
    width: 100px;
    height: 2px;
    margin: 25px 0px 25px 0px;
    background-color: map-get($theme-colors, "dark");
  }
  .content {
    width: 60%;
    text-align: center;
    font-weight: 200;
    margin: 0;
    margin-bottom: 25px;
    color: map-get($theme-colors, "dark");
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 90%;
    }
  }
  .logo {
    width: 300px;
    margin-bottom: 25px;
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 200px;
    }
  }
}

.before-after {
  padding: 50px 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .title {
    width: 45%;
    text-align: center;
    line-height: 50px;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 25px;
    color: map-get($theme-colors, "dark");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 60%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 90%;
    }
  }
  .subtitle {
    width: 50%;
    text-align: center;
    font-weight: 200;
    margin: 0;
    color: map-get($theme-colors, "dark");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 50%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 75%;
    }
  }
  .divider {
    width: 100px;
    height: 2px;
    margin: 25px 0px 25px 0px;
    background-color: map-get($theme-colors, "dark");
  }
  img {
    width: 350px;
    height: 350px;
    margin: 25px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25); 
    object-fit: cover;
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 50%;
      margin: 25px 15px;
      width: 200px;
      height: 200px;
    }
  }
}

.deck-selector {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 0px;
  background-color: map-get($theme-colors, "dark");
  .title {
    width: 45%;
    text-align: center;
    line-height: 50px;
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 50px;
    color: map-get($theme-colors, "white");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 60%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 90%;
    }
  }
  .subtitle {
    width: 45%;
    text-align: center;
    font-weight: 200;
    margin: 0;
    margin-bottom: 25px;
    color: map-get($theme-colors, "white");
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 50%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 75%;
    }
  }
  .divider {
    width: 100px;
    height: 2px;
    margin-bottom: 50px;
    background-color: map-get($theme-colors, "white");
  }
  .content {
    width: 60%;
    text-align: center;
    font-weight: 200;
    margin: 0;
    margin-bottom: 25px;
    color: map-get($theme-colors, "white");
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 90%;
    }
  }
  .main-image {
    border: 3px solid white;
  }
  .options {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (max-width: map-get($break-points, 'large')) {
      width: 60%;
    }
    @media screen and (max-width: map-get($break-points, 'medium')) {
      width: 75%;
    }
    .option {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin: 10px;
      overflow: hidden;
      object-fit: contain;
      border: solid 2px map-get($theme-colors, "white");
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}


.decking-header {
  width: 100%;
  position: relative;
  background-image: url('../../Assets/images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 70px;
  .overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .title {
      width: 45%;
      text-align: center;
      line-height: 50px;
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 25px;
      color: map-get($theme-colors, "white");
      @media screen and (max-width: map-get($break-points, 'large')) {
        width: 60%;
      }
      @media screen and (max-width: map-get($break-points, 'medium')) {
        width: 90%;
      }
    }
  }
}